var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      staticClass: "request-more-bandwidth-dialog",
      attrs: {
        value: _vm.value,
        maxWidth: 500,
        title: _vm.$t("Would you like to request more bandwidth?"),
      },
      on: {
        input: function ($event) {
          return _vm.$emit("input", !_vm.value)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialogOn, attrs: dialogAttrs }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    disabled: !_vm.activatorDisabled,
                    bottom: "",
                    "nudge-top": 60,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: tooltip }) {
                          return [
                            _c(
                              "span",
                              _vm._g({}, { ...tooltip }),
                              [
                                _vm._t("activator", null, null, {
                                  on: { ...dialogOn },
                                  attrs: {
                                    ...dialogAttrs,
                                    disabled: _vm.activatorDisabled,
                                  },
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "You have already requested more bandwidth for this period"
                        )
                      )
                    ),
                  ]),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("p", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "If you feel you do not have enough bandwidth for your work, send us a request and we will contact you with a new offer."
              )
            ) +
            " "
        ),
      ]),
      _c(
        "cs-simple-dialog",
        {
          attrs: {
            maxWidth: "500",
            title: _vm.$t("Your request has been sent successfully!"),
            success: "",
          },
          model: {
            value: _vm.showSuccess,
            callback: function ($$v) {
              _vm.showSuccess = $$v
            },
            expression: "showSuccess",
          },
        },
        [
          _vm._v(
            _vm._s(
              _vm.$t(
                "We have received your request and will contact you shortly for more details on bandwidth extension"
              )
            )
          ),
        ]
      ),
      _c(
        "cs-flex",
        { attrs: { justify: "space-between" } },
        [
          _c(
            "cs-btn",
            {
              staticClass: "mr-4",
              attrs: { outlined: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("input", !_vm.value)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("Cancel")))]
          ),
          _c(
            "v-tooltip",
            {
              attrs: {
                disabled: !_vm.activatorDisabled,
                bottom: "",
                "nudge-top": 60,
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on: tooltip }) {
                    return [
                      _c(
                        "span",
                        _vm._g({}, { ...tooltip }),
                        [
                          _c(
                            "cs-btn",
                            {
                              attrs: { disabled: _vm.activatorDisabled },
                              on: { click: _vm.sendRequestMoreEmail },
                            },
                            [_vm._v(_vm._s(_vm.$t("Send request")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "You have already requested more bandwidth for this period"
                    )
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }