
import SteelspaceLogoSvg from '@/assets/SteelspaceLogo.svg.vue'
import ToolbarMenu from '@/components/ToolbarMenu.vue'
import ChangeThemeButton from '@/components/ChangeThemeButton.vue'
import { CsBtn, CsFlex } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'ExplorerToolbar',
  components: {
    CsFlex,
    CsBtn,
    ToolbarMenu,
    SteelspaceLogoSvg,
    ChangeThemeButton,
  },
})
