var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    { staticClass: "explorer-toolbar py-3 pl-3 pr-8" },
    [
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.$router.push("/explorer")
            },
          },
        },
        [_c("steelspace-logo-svg", { staticClass: "logo" })],
        1
      ),
      _c("change-theme-button"),
      _c(
        "cs-btn",
        {
          staticClass: "ml-3 mr-3",
          attrs: { icon: "", "min-width": "0" },
          on: {
            click: function ($event) {
              return _vm.$router.push("/help")
            },
          },
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ attrs, on }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                        [_vm._v("mdi-help-circle-outline")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Help")))])]
          ),
        ],
        1
      ),
      _c("toolbar-menu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }