
import Vue from 'vue'
import { CsBtn, CsFlex, CsSimpleDialog } from '@consteel/csuetify'
import { getCookie, setCookie } from '@/services/cookie.service'
import { sendRequestMoreEmail } from '@/modules/userService'

export default Vue.extend({
  name: 'RequestMoreBandwidthDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    tooltipDisabled: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CsSimpleDialog,
    CsBtn,
    CsFlex,
  },
  data() {
    return {
      showSuccess: false,
      alreadyRequested: false,
    }
  },
  computed: {
    activatorDisabled(): boolean {
      const cookie = getCookie('requestcookie')
      return cookie === 'true' || this.alreadyRequested
    },
  },
  methods: {
    sendRequestMoreEmail() {
      sendRequestMoreEmail()
      this.showSuccess = true
      setTimeout(() => (this.showSuccess = false), 3000)
      this.alreadyRequested = true
      setCookie('requestcookie', 'true', 30)
      this.$emit('input', !this.value)
    },
  },
})
